<template>
  <div h="full" w="full" class="absolute" flex="~ col">
    <div :style="{ backgroundColor: 'var(--tc-color-bg-list)' }">
      <BusinessBUserHeader class="mx-auto max-w-300" />
    </div>
    <div flex="1" bg="#ffffff" overflow="y-auto">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
await usePageConfig()
</script>

<style>

</style>
